<template>
  <div>
    <div class="container-fluid">
      <PageTitle noAdd />
      <template>
        <card-list
          :filter.sync="filter"
          :idKey="idKey"
          :dataListing.sync="data"
          :fields="fields"

          :no-btn-mass="true"
          :no-multi-select="true"
        >
          <template #filters>
            <b-col md=5>
              <DatePicker 
                v-model="filter.date"
                type="date" 
                range 
                @input="doFilter"
                value-type="DD-MM-YYYY"
                style="width: 100%;"
                input-class="form-control"
                placeholder="Pilih Tanggal"
              />
            </b-col>
          </template>
          <template #actionBy="data">
            {{ data.scope.value.name }}
          </template>
          <template #created_at="data">
            {{ data.scope.value | moment("MMM DD YYYY, HH:mm ") }}
          </template>

          <template #aksi="data">
            <b-button @click="doView(data.scope.item)">
              <i class="icon-eye"></i>
            </b-button>
          </template>
        </card-list>
      </template>

      <b-modal 
      id="ModalDetail" 
      title="Detail Data"
      size="lg"
      ok-only
      :hide-footer="true"
    >
      <template v-if="row._id">
       <div class="inbox-center table-responsive">
              <table class="table m-b-0 table-bordered">
                               <tbody>
                  <tr>
                    <td style="width: 30%;">User</td>
                    <td>{{row.actionBy.name}}</td>
                    
                  </tr>

                   <tr>
                    <td>Aktivitas</td>
                    <td>{{row.title}}</td>
                  </tr>

                    <tr>
                    <td>Tanggal</td>
                    <td>{{ row.created_at | moment("MMM DD YYYY, HH:mm ") }}</td>
                  </tr>


                  <tr>
                    <td>Deskripsi</td>
                    <td>{{row.description}}</td>
                  </tr>

                  <tr>
                    <td>Pesan Error</td>
                    <td>{{row.error_msg}}</td>
                  </tr>

                  <tr>
                    <td>Error Fullstack</td>
                    <td>{{row.error_fullstack}}</td>
                  </tr>

                </tbody>
              </table>
            </div>
      </template>
    </b-modal>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
export default{
  extends:GlobalVue,
  components:{PageTitle, CardList},
  data(){
    return {
      idKey: '_id',
      fields: [
        {
          key: "number", label: '#'
        },
        {
          key: "actionBy", label: "User", is_custom: true
        },
        {
          key: "title", label: "Aktivitas"
        },
        {
          key: "created_at", label: "Tanggal", is_custom: true
        },
        {
          key: "aksi", is_custom: true
        }
      ]
    }
  },
  methods:{
    doView(data){
      this.$set(this,'row', data)
      this.$bvModal.show('ModalDetail')
    }
  },
  mounted(){  
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>